
    import { defineComponent } from 'vue';
    import WorksheetContainer from '@/components/WorksheetContainer.vue';

    export default defineComponent({
        components: {
            WorksheetContainer
        },
        directives: {
        },
        filters: {
        },
        props: {
            selected_lesson_id: {
                type: String,
                required: true
            }
        },
        data() {
            return {
            }
        },
        computed: {
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        methods: {
            refreshWorksheets(): void {
                const ref = this.$refs.worksheets as InstanceType<typeof WorksheetContainer>;
                ref.newWorksheetExtracted();
            },
        },
    });
