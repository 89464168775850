
    import { defineComponent } from 'vue';
    import { NullableTask } from '@/types/TaskInterfaces'
    import LessonProgressStatusLozenge from '@/components/LessonProgressStatusLozenge.vue'
    import AddRemoveButton from './AddRemoveButton.vue';

    export default defineComponent({
        components: {
            LessonProgressStatusLozenge,
            AddRemoveButton,
        },
        directives: {
        },
        filters: {
        },
        props: {
            lesson:
            {
                type: Object as () => NullableTask,
            },
            is_selected: Boolean,
            disallow_change: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
            }
        },
        computed: {
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        methods: {
        },
    });
