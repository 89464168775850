
    import { defineComponent } from 'vue';
    import LessonPlan from './LessonPlan.vue';
    import { LessonPlanType } from '@/types/LessonPlanInterface';
    import { Task, NullableTask } from '@/types/TaskInterfaces';
    import AddRemoveButton from './AddRemoveButton.vue';
    import WaitSpinner from '@/components/WaitSpinner.vue';

    export default defineComponent({
        components: {
            LessonPlan,
            AddRemoveButton,
            WaitSpinner,
        },
        directives: {
        },
        filters: {
        },
        props: {
            student_term_id:
            {
                type: Number,
                required: true,
            },
            all_lessons:
            {
                type: Object as () => Task[],
                required: true,
            },
            selected_lesson: Object as () => NullableTask,
        },
        data() {
            return {
                isActive: false,
                lesson_plans: [] as LessonPlanType[],
                is_loading: false,
                show_in_progress_lesson_plan: false,
                show_recently_visited_lesson_plan: false,
            }
        },
        computed: {
        },
        watch: {
            all_lessons() {
                if (this.isActive)
                    this.refreshLessonPlans();
            }
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        emits: ['lessonSelected'],
        methods: {
            togglePopout() {
                this.isActive = !this.isActive;

                if (this.isActive)
                    this.refreshLessonPlans();
            },

            refreshLessonPlans() {
                let secret_password = this.$cookies.get('secret_password');

                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                this.is_loading = true;

                fetch(`tasks/${this.student_term_id}/LessonPlans`, { headers })
                    .then(r => r.json())
                    .then(json => {
                        this.lesson_plans = json['lessonPlans'];    //TODO: do we really need the additional nest here - can't return the list directly?
                        this.is_loading = false;
                        return;
                    });
            },

            newLessonPlan() {
                let secret_password = this.$cookies.get('secret_password');

                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                var newLessonPlanName = prompt("New Lesson Plan Name", "");

                if (newLessonPlanName != null && newLessonPlanName != "") {

                    this.is_loading = true;

                    fetch(`tasks/${this.student_term_id}/LessonPlans/New/${newLessonPlanName}`, { headers })
                        .then(() => this.refreshLessonPlans());

                }
            },

            removeLessonPlan(lessonPlanId: Number) {
                let secret_password = this.$cookies.get('secret_password');

                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                if (confirm("Are you sure you want to remove this lesson plan?")) {

                    this.is_loading = true;

                    fetch(`tasks/${this.student_term_id}/LessonPlans/${lessonPlanId}/Remove`, { headers })
                        .then(() => this.refreshLessonPlans());

                }
            },

            selectLesson(lesson: Task): void {
                this.$emit('lessonSelected', null); //remove any existing lesson detail to allow a "clean" reload
                this.$nextTick(() => {
                    this.$emit('lessonSelected', lesson);
                });
            },

            addCurrentLesson(lessonPlanId: Number): void {
                if (!this.selected_lesson) {
                    return;
                }

                this.is_loading = true;

                let secret_password = this.$cookies.get('secret_password');

                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                fetch(`tasks/${this.student_term_id}/LessonPlans/${lessonPlanId}/Add/${this.selected_lesson.id}`, { headers })
                    .then(() => {
                        this.refreshLessonPlans();
                        return;
                    });
            },

            removeLesson(lessonPlanId: Number, lesson: Task): void {
                this.is_loading = true;

                let secret_password = this.$cookies.get('secret_password');

                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                fetch(`tasks/${this.student_term_id}/LessonPlans/${lessonPlanId}/Remove/${lesson.id}`, { headers })
                    .then(() => {
                        this.refreshLessonPlans();
                        return;
                    });
            },
        },
    },
    );
