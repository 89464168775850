
    import { defineComponent } from 'vue';
    import StudentGradeTermSelector from '@/components/StudentGradeTermSelector.vue';
    import LessonGrid from '@/components/LessonGrid.vue';
    import LessonDetail from '@/components/LessonDetail.vue';
    import WaitSpinner from '@/components/WaitSpinner.vue';
    import { Task, NullableTask } from '../types/TaskInterfaces';
    import LessonPlanPopout from '@/components/LessonPlans/LessonPlanPopout.vue';
    import NotesPopout from '@/components/LessonNotes/NotesPopout.vue';

    //import DropZone from 'DropZone.vue'

    export default defineComponent({
        components: {
            StudentGradeTermSelector,
            LessonGrid,
            LessonDetail,
            WaitSpinner,
            LessonPlanPopout,
            NotesPopout,
        },
        directives: {
        },
        filters: {
        },
        props: {
        },
        data() {
            return {
                selected_lesson: null as NullableTask,
                loading_grid: false,
                loading_student_grade_term_selector: false,
                changing_workflow_status: false,
                selected_term: '',   //TODO: sync properly with child
                border_colour: '',
                all_lessons: [] as Task[],
            };
        },
        computed: {
        },
        watch: {
            border_colour(newColour) {
                document.documentElement.style.setProperty('--border_color', newColour);
            }
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
            this.border_colour = '#FFFFFF';
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        methods: {
            refreshLessonGrid(selected_term: string): void {
                this.selected_term = selected_term; //TODO: sync this properly with child component
                const ref = this.$refs.lessonGridRef as InstanceType<typeof LessonGrid>;
                ref.loadTasks(selected_term);
            },

            selectLesson(selected_lesson: NullableTask): void {
                this.selected_lesson = selected_lesson;
            },

            lessonCompletionStatusChanged(status: boolean): void {
                if (this.selected_lesson) {
                    this.selected_lesson.isCompleted = status;
                    //this.refreshLessonGrid(''); //TODO: alternative needed - this was intended as a workaround for HS-32/HS-33
                }
            },

            lessonProgressStatusChanged(status: boolean): void {
                if (this.selected_lesson) {
                    this.selected_lesson.isInProgress = status;
                    //this.refreshLessonGrid(''); //TODO: alternative needed - this was intended as a workaround for HS-32/HS-33
                }
            },

            updateLessonTitle(newTitle: string) {
                if (!this.selected_lesson)
                    return;

                let secret_password = this.$cookies.get('secret_password');

                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json", "SecretPassword": secret_password }
                };

                if (newTitle == "")
                    requestOptions.method = "DELETE";

                fetch(`task/${this.selected_lesson.id}/Title/${newTitle}`, requestOptions)
                    .then(() => {   //TODO: handle response?
                        this.selected_lesson!.lessonTitle = newTitle;
                        return;
                    });
            },

            updateLessonNotes(newNotes: string) {
                if (!this.selected_lesson)
                    return;

                let secret_password = this.$cookies.get('secret_password');

                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json", "SecretPassword": secret_password }
                };

                if (newNotes == "")
                    requestOptions.method = "DELETE";

                fetch(`task/${this.selected_lesson.id}/Notes/${newNotes}`, requestOptions)
                    .then(() => {   //TODO: handle response?
                        this.selected_lesson!.notes = newNotes;
                        return;
                    });
            },

        },
    });

    // hack to allow for toolbars and stuff on mobile browsers
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
