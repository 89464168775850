
    import { defineComponent } from 'vue';
    import { Task } from '@/types/TaskInterfaces';

    export default defineComponent({
        components: {
        },
        directives: {
        },
        filters: {
        },
        props: {
            lesson: {
                type: Object as () => Task,
                required: true,
            }
        },
        data() {
            return {
            }
        },
        computed: {
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        methods: {
        },
    });
