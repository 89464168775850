import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11f12a4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"white-space":"nowrap","margin-bottom":"10px"} }
const _hoisted_2 = ["title"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubjectWeekLessonListSimple = _resolveComponent("SubjectWeekLessonListSimple")!
  const _component_SubjectWeekLessonListDefault = _resolveComponent("SubjectWeekLessonListDefault")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
                        'header',
                        _ctx.getCompletionClass()
                    ])
    }, [
      (_ctx.summary?.equipmentList)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            title: _ctx.summary.equipmentList,
            onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.alert(_ctx.summary.equipmentList);}),
            style: {"float":"left","margin-right":"0.5em"},
            class: "clickable"
          }, " ✎ ", 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(_ctx.getStatusText()), 1)
    ], 2),
    (_ctx.format==1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_SubjectWeekLessonListSimple, {
            lessons: _ctx.lessons,
            lesson_count: _ctx.lessons.length,
            show_completed_lessons: _ctx.show_completed_lessons,
            only_show_work_samples: _ctx.only_show_work_samples,
            show_attachment_icon: _ctx.show_attachment_icon,
            search_results: _ctx.search_results,
            onLessonSelected: _ctx.selectLesson
          }, null, 8, ["lessons", "lesson_count", "show_completed_lessons", "only_show_work_samples", "show_attachment_icon", "search_results", "onLessonSelected"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_SubjectWeekLessonListDefault, {
            lessons: _ctx.lessons,
            lesson_count: _ctx.lessons.length,
            show_completed_lessons: _ctx.show_completed_lessons,
            only_show_work_samples: _ctx.only_show_work_samples,
            show_attachment_icon: _ctx.show_attachment_icon,
            search_results: _ctx.search_results,
            onLessonSelected: _ctx.selectLesson
          }, null, 8, ["lessons", "lesson_count", "show_completed_lessons", "only_show_work_samples", "show_attachment_icon", "search_results", "onLessonSelected"])
        ]))
  ]))
}