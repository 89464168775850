
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
        directives: {
        },
        filters: {
        },
        props: {
            roundedTop: {
                type: Boolean,
                default: true
            },
            roundedBottom: {
                type: Boolean,
                default: true
            },
            dots: {
                type: Number,
                default: null
            },
            size: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                topRadius: 0,
                bottomRadius: 0,
            }
        },
        computed: {
        },
        watch: {
            roundedTop() {
                this.setBarRadiuses();
            },
            roundedBottom() {
                this.setBarRadiuses();
            },
            dots() {
                this.setBarRadiuses();
            }
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
            this.setBarRadiuses();
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        methods: {
            setBarRadiuses(): void {
                const radius = 0.2;

                //TODO: this whole lot isn't really working as expected...

                if (this.dots) {
                    this.topRadius = radius;
                    this.bottomRadius = radius;
                    return;
                }

                if (this.roundedTop)
                    this.topRadius = radius;
                else
                    this.topRadius = 0;

                if (this.roundedBottom)
                    this.bottomRadius = radius;
                else
                    this.bottomRadius = 0;
            }
        },
    });
