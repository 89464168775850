
    import { defineComponent } from 'vue';
    import { Task, TaskWeeklySummary, NullableTask } from '@/types/TaskInterfaces'
    import LightBar from '@/components/LightBar.vue';
    import SubjectWeekLessonContainer from '@/components/SubjectWeekLessonContainer.vue'

    export default defineComponent({
        components: {
            LightBar,
            SubjectWeekLessonContainer
        },
        directives: {
        },
        filters: {
        },
        props: {
            loading: Boolean,
            selected_lesson: Object as () => NullableTask,
        },
        data() {
            return {
                selected_term: '',
                subjects: [] as object[],
                weeks: [] as number[],
                tasks_weekly_summary: [] as TaskWeeklySummary[],
                tasks: [] as Task[],
                current_week_in_term: 0 as number,
                show_completed_lessons: true,
                only_show_work_samples: false,
                show_attachment_icon: true,
                search_string: "",
                search_results: [] as string[],
                search_disabled: false,
                search_content: true,
                search_notes: false,
                intervalId: 0,
                last_update: new Date(1900, 1, 1).toUTCString(),
                refresh_countdown: 0,
            }
        },
        computed: {
        },
        watch: {
            selected_term(): void {
                //console.log('selected_term changed');
                this.resetTimer();
            },

            selected_lesson(newLesson: NullableTask, oldLesson: NullableTask): void {
                if (JSON.stringify(newLesson) != JSON.stringify(oldLesson)) {
                    //console.log('selected_lesson changed');
                    this.resetTimer();
                }
            },
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
            clearInterval(this.intervalId);
        },
        unmounted() {
        },
        emits: ['update:selected_lesson', 'update:loading', 'tasksLoaded'],
        methods: {
            resetTimer(): void {
                //console.log('timer reset/starting');

                clearInterval(this.intervalId);
                this.refresh_countdown = 60;

                this.intervalId = setInterval(() => {
                    this.timerTick();
                }, 60000);
            },

            timerTick(): void {
                //console.log(`timer tick - ${this.refresh_countdown} remaining`);

                if (this.refresh_countdown <= 0) {
                    clearInterval(this.intervalId);
                    return;
                }

                //decrease the countdown
                this.refresh_countdown -= 1;

                //refresh the grid without the loading overlay
                this.loadTasks('');
            },

            loadTasks(selected_term: string): void {
                this.search_string = "";
                this.search_results = [];

                if (selected_term != '') {
                    this.selected_term = selected_term;
                    this.changeLoadingState(true);
                }

                if (this.selected_term == '') {
                    this.changeLoadingState(false);
                    return;
                }

                let secret_password = this.$cookies.get('secret_password');

                //this.changeLoadingState(true);

                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                fetch('tasks/' + this.selected_term, { headers })
                    .then(r => r.json())
                    .then(json => {
                        this.tasks = json.tasks;
                        this.tasks_weekly_summary = json.taskWeeklySummary;
                        this.subjects = json.subjects;
                        this.weeks = json.weeks;
                        this.current_week_in_term = json.currentWeekInTerm;
                        this.changeLoadingState(false);
                        this.last_update = new Date().toUTCString();
                        this.$emit('tasksLoaded', this.tasks);

                        if (this.selected_lesson) {
                            //if we have a lesson selected, replace it with the newly-loaded data (which may be unchanged)
                            var newLesson = this.tasks.find(x => x.id == this.selected_lesson?.id);
                            this.$emit('update:selected_lesson', newLesson);
                        }

                        return;
                    });
            },

            changeLoadingState(isLoading: boolean): void {
                this.$emit('update:loading', isLoading);
            },

            promptToRenameSubject(subject: string, subjectSubtitle: string): void {
                if (subjectSubtitle != null)
                    return;

                let secret_password = this.$cookies.get('secret_password');
                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                this.changeLoadingState(true);

                var newSubjectName = prompt("Change Subject Name", subject);

                if (newSubjectName == null || newSubjectName == subject) {
                    this.changeLoadingState(false);
                    return;
                }

                subject = encodeURIComponent(subject);
                newSubjectName = encodeURIComponent(newSubjectName);

                fetch(`tasks/${this.selected_term}/RenameSubject/${subject}/${newSubjectName}`, { headers })
                    .then(() => {
                        this.loadTasks('');
                        return;
                    });
            },

            searchLessonsForString(): void {
                if (this.search_string.length < 4) {
                    this.search_results = [];
                    return;
                }

                let secret_password = this.$cookies.get('secret_password');
                const headers = {
                    "Content-Type": "application/json",
                    "SecretPassword": secret_password,
                    "SearchContent": this.search_content ? "1" : "0",
                    "SearchNotes": this.search_notes ? "1" : "0",
                };
                this.search_disabled = true;

                fetch(`tasks/${this.selected_term}/Search/${this.search_string}`, { headers })
                    .then(r => r.json())
                    .then(json => {
                        this.search_results = json;
                        this.search_disabled = false;
                        return;
                    });
            },

        },
    });
