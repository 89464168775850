
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
        directives: {
        },
        filters: {
        },
        props: {
            disabled: Boolean,
            loading: Boolean,
        },
        data() {
            return {
                students: [],
                selected_student_id: null,
                selected_grade: '',
                selected_term: '',  //TODO: make this a prop to sync with parent better/properly
                secret_password: ''
            }
        },
        computed: {
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
            this.secret_password = this.$cookies.get('secret_password');
            this.loadStudents();
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        emits: ['selectedTermChanged', 'update:loading', 'changeBorderColour'],
        methods: {
            async loadStudents() {
                this.selected_student_id = null;
                this.$emit('changeBorderColour', '#FFFFFF');

                if (this.secret_password == '') {
                    return;
                }

                this.changeLoadingState(true);

                const headers = { "Content-Type": "application/json", "SecretPassword": this.secret_password };

                try {
                    const response = await fetch('students', { headers });

                    if (!response.ok) {
                        this.students = [];
                        //TODO: need better feedback to user on failure
                    }
                    else {
                        this.students = await response.json();
                        this.$cookies.set("secret_password", this.secret_password);
                    }
                }
                catch (error) {
                    this.students = [];
                    //TODO: need better feedback to user on failure
                }

                this.changeLoadingState(false);
                return;
            },

            logOff(): void {
                this.$cookies.remove('secret_password');
                location.reload();
            },

            async setDefaultOptions() {
                this.changeLoadingState(true);

                const selectedStudent: any = this.students.find((student: any) => student.id === this.selected_student_id);
                this.$emit('changeBorderColour', '#' + selectedStudent.colourCode ?? 'FFFFFF');

                const defaultGrade = selectedStudent.studentGrades.find((grade: any) => grade.isDefault);
                const defaultTerm = defaultGrade.studentTerms.find((term: any) => term.isDefault);

                this.selected_grade = defaultGrade.grade;
                this.selected_term = defaultTerm.id;
                this.$emit('selectedTermChanged', this.selected_term); //TODO: can we consolidate the need to have two event triggers? or at least put this in a single method...?

                this.changeLoadingState(false);
            },

            changeLoadingState(isLoading: boolean): void {
                this.$emit('update:loading', isLoading);
            },
        },
    });
