import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15848dd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lesson-status-outside" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass({
                'lesson-status-inside': true,
                'complete': _ctx.lesson.isCompleted,
                'incomplete': !_ctx.lesson.isCompleted && !_ctx.lesson?.isInProgress,
                'in-progress': !_ctx.lesson.isCompleted && _ctx.lesson?.isInProgress
            })
    }, _toDisplayString(_ctx.lesson?.isCompleted ? "Complete" : (_ctx.lesson?.isInProgress ? "In Progress" : "Incomplete")), 3)
  ]))
}