
    import { defineComponent } from 'vue';
    import LessonTile from './LessonTile.vue';
    import { Task } from '@/types/TaskInterfaces'
    import AddRemoveButton from './AddRemoveButton.vue';

    export default defineComponent({
        components: {
            LessonTile,
            AddRemoveButton,
        },
        directives: {
        },
        filters: {
        },
        props: {
            lesson_plan_name: {
                type: String,
                required: true
            },
            lesson_ids: {
                type: Object as () => String[],
                required: true,
            },
            all_lessons:
            {
                type: Object as () => Task[],
                required: true,
            },
            selected_lesson_id: String,
            disallow_change: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
            }
        },
        computed: {
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        methods: {
            addCurrentLesson(): void {
                this.$emit('addCurrentLesson');
            },

            removeLesson(lesson: Task): void {
                this.$emit('removeLesson', lesson);
            },

            selectLesson(lesson_id: String): void {
                if (this.selected_lesson_id == lesson_id) {
                    // if we click an already-selected lesson, then close the pop-out
                    this.$emit('closePopout');
                }
                else {
                    let lesson = this.all_lessons.find(x => x.id == lesson_id) || null;
                    this.$emit('lessonSelected', lesson);
                }


            },
        },
    });
