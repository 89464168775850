import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-472b2f00"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "button add"
}
const _hoisted_2 = {
  key: 1,
  class: "button remove"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.mode == 'add')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_ctx.mode == 'remove')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true)
}