import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b4a464c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "contents" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "lesson-description" }
const _hoisted_6 = {
  key: 0,
  class: "remove-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LessonProgressStatusLozenge = _resolveComponent("LessonProgressStatusLozenge")!
  const _component_AddRemoveButton = _resolveComponent("AddRemoveButton")!

  return (_ctx.lesson)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({'lesson-tile': true, 'selected': _ctx.is_selected}),
        title: _ctx.lesson.lessonTitle
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass({'lesson-info':true, 'work-sample': _ctx.lesson.isForSubmission})
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.lesson.subject), 1),
            (_ctx.lesson.subjectSubtitle)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, " [" + _toDisplayString(_ctx.lesson.subjectSubtitle) + "] ", 1))
              : _createCommentVNode("", true),
            _createElementVNode("span", null, " W" + _toDisplayString(_ctx.lesson.week), 1),
            (_ctx.lesson.totalLessonsInWeek > 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, " L" + _toDisplayString(_ctx.lesson.lesson), 1))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.lesson.lessonTitle), 1),
          _createVNode(_component_LessonProgressStatusLozenge, { lesson: _ctx.lesson }, null, 8, ["lesson"])
        ]),
        (!_ctx.disallow_change)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_AddRemoveButton, {
                mode: "remove",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (this.$emit('lessonRemoved', _ctx.lesson)), ["stop"]))
              })
            ]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}