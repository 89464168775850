import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07a504f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lesson-plan" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LessonTile = _resolveComponent("LessonTile")!
  const _component_AddRemoveButton = _resolveComponent("AddRemoveButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.lesson_plan_name), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lesson_ids, (lesson_id) => {
      return (_openBlock(), _createBlock(_component_LessonTile, {
        key: lesson_id,
        lesson: _ctx.all_lessons.find(x => x.id == lesson_id) || null,
        is_selected: _ctx.selected_lesson_id == lesson_id,
        disallow_change: _ctx.disallow_change,
        onClick: _withModifiers(($event: any) => (_ctx.selectLesson(lesson_id)), ["stop"]),
        onLessonRemoved: _ctx.removeLesson
      }, null, 8, ["lesson", "is_selected", "disallow_change", "onClick", "onLessonRemoved"]))
    }), 128)),
    (_ctx.selected_lesson_id && !_ctx.disallow_change && !_ctx.lesson_ids.find(x => x == _ctx.selected_lesson_id))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "add-current-lesson-link",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addCurrentLesson && _ctx.addCurrentLesson(...args)))
        }, [
          _createVNode(_component_AddRemoveButton, { mode: "add" }),
          _createTextVNode(" Add Lesson ")
        ]))
      : _createCommentVNode("", true)
  ]))
}