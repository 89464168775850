import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bc4d4a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("embed", {
        src: _ctx.url + '#view=FitH',
        type: "application/pdf",
        width: "100",
        height: "150",
        style: {"border":"1px dashed black"}
      }, null, 8, _hoisted_1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("a", {
        href: _ctx.url,
        target: "_blank",
        rel: "noreferrer noopener",
        class: "open-link"
      }, "Open in New Tab", 8, _hoisted_2)
    ])
  ], 64))
}