
    import { defineComponent } from 'vue';
    import OneDriveLink from './OneDriveLink.vue';

    export default defineComponent({
        components: {
            OneDriveLink
        },
        directives: {
        },
        filters: {
        },
        props: {
            title: {
                type: String,
                required: true
            },
            notes: String,
            filePath: String,
            filePathAlt: String,
        },
        data() {
            return {
                isActive: false,
                localTitle: this.title,
                titleDisabled: false,
                localNotes: this.notes,
                notesDisabled: false,
            }
        },
        computed: {
        },
        watch: {
            title(newVal) {
                this.localTitle = newVal;
            },
            notes(newVal) {
                this.localNotes = newVal;
            },
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        methods: {
            togglePopout() {
                this.isActive = !this.isActive;
            },

            updateTitle() {
                if (this.localTitle != this.title)
                    this.$emit('updateTitle', this.localTitle);
            },

            updateNotes() {
                if (this.localNotes != this.notes)
                    this.$emit('updateNotes', this.localNotes);
            },
        },
    });
