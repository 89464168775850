import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-600be27c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lessons.filter((x) => _ctx.show_completed_lessons || !x.isCompleted), (lesson) => {
    return (_openBlock(), _createElementBlock("div", {
      key: lesson,
      onClick: ($event: any) => (_ctx.selectLesson(lesson)),
      class: _normalizeClass({
                        'lesson-link': true,
                        'clickable': true,
                        'work-sample': lesson.isForSubmission,
                        'complete': lesson.isCompleted,
                        'not-started': !lesson.isCompleted && !lesson.isInProgress,
                        'in-progress': lesson.isInProgress,
                        'highlighted': _ctx.search_results.includes(lesson.id),
                    }),
      title: lesson.lessonTitle
    }, _toDisplayString(this.getDisplayText(lesson)), 11, _hoisted_1))
  }), 128))
}