<template>
    <div>
        <button @click="copyText">
            Copy Path{{additionalText}}
        </button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        props: {
            filePath: String,
            additionalText: String,
        },
        methods: {
            copyText() {
                navigator.clipboard.writeText(this.filePath);
            },
        },
    };
</script>
