import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bbed1c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "light-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!this.dots)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "light bar",
          style: _normalizeStyle({
                    '--topRadius': _ctx.topRadius,
                    '--bottomRadius': _ctx.bottomRadius,
                    'height': _ctx.size + 'em',
                })
        }, null, 4))
      : _createCommentVNode("", true),
    (this.dots)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3], (i) => {
              return _createElementVNode("div", { key: i }, [
                _createElementVNode("div", {
                  class: "light dots",
                  style: _normalizeStyle({
                    '--topRadius': _ctx.topRadius,
                    '--bottomRadius': _ctx.bottomRadius
                })
                }, null, 4)
              ])
            }), 64))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}