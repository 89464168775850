
    import { defineComponent } from 'vue';
    import EmbeddedWorksheet from '@/components/EmbeddedWorksheet.vue';

    export default defineComponent({
        components: {
            EmbeddedWorksheet
        },
        directives: {
        },
        filters: {
        },
        props: {
            selected_lesson_id: {
                type: String,
                required: true
            },
            is_master: Boolean,
        },
        data() {
            return {
                loading: false,
                attachments: [],
                attachments_visible: false,
            };
        },
        computed: {
        },
        watch: {
            attachments_visible() {
                if (this.attachments_visible) {
                    this.loadAttachments();
                }
            },
            selected_lesson_id() {
                if (this.selected_lesson_id) {
                    this.loadAttachments();
                }
            }
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        emits: ['extractionComplete', 'worksheetChanged'],
        methods: {
            loadAttachments(): void {
                let secret_password = this.$cookies.get('secret_password');

                this.loading = true;

                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                var type = this.is_master ? "M" : "W";

                fetch(`task/${this.selected_lesson_id}/GetWorksheets/${type}`, { headers })
                    .then(r => r.json())
                    .then(json => {
                        this.attachments = json;
                        this.loading = false;
                        return;
                    });
            },

            processExtraction(attachment: any): void {
                if (!this.is_master)
                    return;

                let secret_password = this.$cookies.get('secret_password');

                this.loading = true;

                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                fetch(`task/${this.selected_lesson_id}/SplitMasterWorksheetByPages/${attachment.attachmentId}/${attachment.extractionPageRef}`, { headers })
                    .then(async () => {
                        this.loading = false;

                        //tell the parent so that we can refresh the other worksheets
                        this.$emit('extractionComplete');

                        return; //TODO: check if these return lines are really needed or if they are effectively redundant
                    });
            },

            newWorksheetExtracted(): void {
                if (this.attachments_visible)
                    this.loadAttachments();
                else
                    this.attachments_visible = true;
            },

            unlinkWorksheet(attachment: any): void {
                if (this.is_master)
                    return;

                if (!confirm("Are you sure?"))
                    return;

                let secret_password = this.$cookies.get('secret_password');

                this.loading = true;

                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                fetch(`task/${this.selected_lesson_id}/UnlinkWorksheet/${attachment.attachmentId}`, { headers })
                    .then(async () => {
                        this.loading = false;

                        //tell the parent so that we can refresh the other worksheets
                        this.$emit('worksheetChanged');

                        return; //TODO: check if these return lines are really needed or if they are effectively redundant
                    });
            },
        },
    });
