
    import { defineComponent, PropType } from 'vue';
    import { Task } from '@/types/TaskInterfaces';

    export default defineComponent({
        components: {
        },
        directives: {
        },
        filters: {
        },
        props: {
            lessons: {
                type: Array as PropType<Task[]>,
                required: true
            },
            lesson_count: {
                type: Number,
                required: true
            },
            show_completed_lessons: {
                type: Boolean,
                default: true
            },
            only_show_work_samples: {
                type: Boolean,
                default: false
            },
            show_attachment_icon: {
                type: Boolean,
                default: false
            },
            search_results: Object as () => string[],
        },
        data() {
            return {
            }
        },
        computed: {
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        emits: ['lessonSelected'],
        methods: {
            selectLesson(lesson: Task): void {
                this.$emit('lessonSelected', lesson);
            },

            getDisplayText(lesson: Task): string {
                if (this.lesson_count == 1)
                    return "";
                else
                    return lesson.lesson.toString();
            }
        },
    });
