
    import { defineComponent } from 'vue';
    import { Task, NullableTask } from '@/types/TaskInterfaces'
    import WorksheetManager from '@/components/WorksheetManager.vue';
    import WaitSpinner from '@/components/WaitSpinner.vue';
    import LessonProgressStatusLozenge from '@/components/LessonProgressStatusLozenge.vue'

    export default defineComponent({
        components: {
            WorksheetManager,
            WaitSpinner,
            LessonProgressStatusLozenge,
        },
        directives: {
        },
        filters: {
        },
        props: {
            selected_lesson: Object as () => NullableTask,
            changing_workflow_status: Boolean,
            all_lessons:
            {
                type: Object as () => Task[],
                required: true,
            },
        },
        data() {
            return {
                content: '',
                previousTaskId: '',
                nextTaskId: ''
            }
        },
        computed: {
        },
        watch: {
            selected_lesson(newLesson: NullableTask, oldLesson: NullableTask): void {
                if (newLesson?.id != oldLesson?.id) {
                    this.loadLessonDetail();
                }
            }
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
            document.body.classList.add("no-scroll");
            window.addEventListener('keydown', this.keyListener); //HS-28 to allow ESC to close modal
            this.loadLessonDetail();
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
            document.body.classList.remove("no-scroll");
            window.removeEventListener('keydown', this.keyListener); //HS-28 to stop ESC to close modal
        },
        unmounted() {
        },
        methods: {
            loadLessonDetail(): void {
                if (!this.selected_lesson) {
                    this.content = '';
                    return;
                }

                let secret_password = this.$cookies.get('secret_password');

                const headers = { "Content-Type": "application/json", "SecretPassword": secret_password };

                fetch('task/' + this.selected_lesson.id, { headers })
                    .then(r => r.json())
                    .then(json => {
                        this.content = json.content;
                        this.previousTaskId = json.previousTaskId;
                        this.nextTaskId = json.nextTaskId;
                        return;
                    });
            },

            markComplete(): void {
                if (!this.selected_lesson) {
                    return;
                }

                let secret_password = this.$cookies.get('secret_password');

                this.$emit('update:changing_workflow_status', true);

                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json", "SecretPassword": secret_password }
                };

                fetch("task/" + this.selected_lesson.id + '/MarkComplete', requestOptions)
                    .then(() => {   //TODO: handle response?
                        this.$emit('progressStatusChanged', false);
                        this.changeCompletionStatus(true);
                        return;
                    });
            },

            markIncomplete(): void {
                if (!this.selected_lesson) {
                    return;
                }

                let secret_password = this.$cookies.get('secret_password');

                this.$emit('update:changing_workflow_status', true);

                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json", "SecretPassword": secret_password }
                };

                fetch("task/" + this.selected_lesson.id + '/MarkIncomplete', requestOptions)
                    .then(() => {   //TODO: handle response?
                        this.changeCompletionStatus(false);
                        return;
                    });
            },

            changeCompletionStatus(status: boolean): void {
                //tell the parent about it so that we can refresh the grid status (and push the status back)
                this.$emit('completionStatusChanged', status);

                //close the window if we have completed the lesson
                if (status)
                    this.clearSelectedLesson();

                this.$emit('update:changing_workflow_status', false);
            },

            changeProgressStatus(status: boolean): void {
                if (!this.selected_lesson)
                    return;

                let secret_password = this.$cookies.get('secret_password');

                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json", "SecretPassword": secret_password }
                };

                var action = status ? "StartProgress" : "StopProgress";

                fetch(`task/${this.selected_lesson.id}/${action}`, requestOptions)
                    .then(() => {   //TODO: handle response?
                        //tell the parent about it so that we can update everything
                        this.$emit('progressStatusChanged', status);
                    });
            },

            clearSelectedLesson(): void {
                this.$emit('clearSelectedLesson');
            },

            keyListener(e: KeyboardEvent) {
                if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
                    if (!this.changing_workflow_status)
                        this.clearSelectedLesson();
                }
            },

            selectLesson(lesson_id: String): void {
                this.content = '';
                let lesson = this.all_lessons.find(x => x.id == lesson_id) || null;
                this.$emit('lessonSelected', lesson);
            },

            markPrinted(isPrinted: boolean): void {
                if (!this.selected_lesson) {
                    return;
                }

                let secret_password = this.$cookies.get('secret_password');

                const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json", "SecretPassword": secret_password }
                };

                fetch(`task/${this.selected_lesson.id}/MarkPrinted/${isPrinted}`, requestOptions)
                    .then(() => {   //TODO: handle response?
                        //this.$emit('lessonSelected', this.selected_lesson.id);
                        this.selected_lesson!.isPrinted = isPrinted;
                        return;
                    });
            },
        },
    });
