
    import { defineComponent, PropType } from 'vue';
    import { Task, TaskWeeklySummary } from '@/types/TaskInterfaces'
    import SubjectWeekLessonListDefault from '@/components/SubjectWeekLessonLists/SubjectWeekLessonListDefault.vue';
    import SubjectWeekLessonListSimple from '@/components/SubjectWeekLessonLists/SubjectWeekLessonListSimple.vue';

    export default defineComponent({
        components: {
            SubjectWeekLessonListDefault,
            SubjectWeekLessonListSimple,
        },
        directives: {
        },
        filters: {
        },
        props: {
            lessons: {
                type: Array as PropType<Task[]>,
                required: true
            },
            summary: {
                type: Object as PropType<TaskWeeklySummary>,
                required: true
            },
            format: {
                type: Number,
                default: 0
            },
            show_completed_lessons: {
                type: Boolean,
                default: true,
            },
            only_show_work_samples: {
                type: Boolean,
                default: false,
            },
            show_attachment_icon: {
                type: Boolean,
                default: false
            },
            search_results: Object as () => string[],
        },
        data() {
            return {
            }
        },
        computed: {
        },
        watch: {
        },
        beforeCreate() {
        },
        created() {
        },
        beforeMount() {
        },
        mounted() {
        },
        updated() {
        },
        activated() {
        },
        deactivated() {
        },
        beforeUnmount() {
        },
        unmounted() {
        },
        emits: ['lessonSelected'],
        methods: {
            getCompletionClass(): string {
                const countCompleted = this.lessons.filter((x) => x.isCompleted).length;
                const countInProgress = this.lessons.filter((x) => x.isInProgress).length;
                const countTotal = this.lessons.length;

                if (countCompleted == 0 && countInProgress == 0)
                    return 'not-started';
                else if (countCompleted == countTotal)
                    return 'complete';
                else
                    return 'partial';
            },

            getStatusText(): string {
                const countCompleted = this.lessons.filter((x) => x.isCompleted).length;
                const countInProgress = this.lessons.filter((x) => x.isInProgress).length;
                const countTotal = this.lessons.length;

                if (countCompleted == 0 && countInProgress == 0)
                    return 'Not Started';
                else if (countCompleted == countTotal)
                    return 'Complete';
                else
                    return `${countCompleted} / ${countTotal}`;
            },

            selectLesson(lesson: Task): void {
                this.$emit('lessonSelected', lesson);
            },

            alert(msg: string): void {
                window.alert(msg);
            },
        },
    });
