
    import { defineComponent } from 'vue';
    import SchoolManager from './components/SchoolManager.vue'

    export default defineComponent({
        name: 'App',
        components: {
            SchoolManager
        }
    });
